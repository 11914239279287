import React, {useState} from 'react';
import {post} from "axios";
import {message, Spin, Upload} from "antd";

const {Dragger} = Upload;

const WordsUplaod = ({token}) => {

  const [loading, setLoading] = useState()

  const fileUpload = async (file) => {
    const url = `${process.env.REACT_APP_ENDPOINT_URL}/uploadWords`;

    const formData = new FormData();

    formData.append('file', file)

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Access-Control-Allow-Headers': '*'
      }
    }

    try {
      setLoading(true)
      await post(url, formData, config)
      message.success('Success!!!')
    } catch (e) {
      message.error('Error!!!')

    } finally {
      setLoading(false)
    }
  }


  return (
    <div>
      <Dragger
        fileList={[]}
        onChange={(info) => {

          console.log(info?.file?.originFileObj, 'info')

          fileUpload(info?.file?.originFileObj)


        }}
      >
        {loading ? <Spin/> : <>
          <p className="ant-upload-drag-icon">
          </p>
          <p className="ant-upload-text">Click or drag CSV file to this area to upload words</p>
          <p className="ant-upload-hint">
            <p className="ant-upload-drag-icon">
            </p>
          </p>
        </>}

      </Dragger>
    </div>
  );
};

export default WordsUplaod;
