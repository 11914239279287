import React from 'react';
import {Wrapper} from "./Home";

const Cztery = () => {
  return (
    <Wrapper
    >
      <div
        style={{
          color: 'white',
          fontSize: '50px',
          fontWeight: 200,
        }}
      >
        404
      </div>
      <div
        style={{
          color: 'white',
          fontSize: '30px',
          fontWeight: 700,
        }}
      >
        Page not found
      </div>
    </Wrapper>
  );
};

export default Cztery;