import React from 'react';
import {useMediaQuery} from 'react-responsive'

export const Wrapper = ({children}) => <div
  style={{
    display: 'flex',
    padding: '0px 20px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "column",
    height: '100vh',

    background: 'linear-gradient(180deg, #A76BF3 0%, rgba(242, 121, 178, 0.85) 100%)'
    // background: 'linear-gradient(180deg, #A76BF3 0%, rgba(242, 121, 178, 0.85) 100%)'
  }}
>
  {children}
</div>

function Home() {

  const isMobile = useMediaQuery({query: '(max-width: 600px)'})


  return (
    <Wrapper

    >


      <div
        className={'animate__animated animate__delay-1s animate__fadeIn'}

        style={{
          color: 'white',
          fontFamily: 'Poppins',
          marginBottom: '20px',
          fontSize: isMobile ? '24px' : '25px',
          fontWeight: 300,

        }}
      >
        <b>English is</b> <span style={{
        fontStyle: 'italic', fontWeight: 600,
        fontFamily: 'Segoe UI'
      }}>ˈkreɪzi.</span>
      </div>
      <div
        className={'animate__animated animate__delay-1s animate__fadeIn'}
        style={{
          color: 'white',
          fontFamily: 'Poppins',
          marginBottom: isMobile ? '0px' : '20px',

          fontSize: isMobile ? '14px' : '16px',
          fontWeight: 300

        }}
      >
        learn how to say and spell top 5000 words
      </div>
      <div
        // id="mainCircle"
        className="rounded-full mt-8  flex items-center justify-center  "
        style={{
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
          cursor: 'pointer',
          background: 'linear-gradient(180deg, #C34FE4 0%, rgba(209, 140, 142, 0.924167) 75.52%, rgba(233, 193, 76, 0.87) 100%)',
          height: '284px',
          width: '284px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: "column",
          // margin: '15px 0px',
          margin: isMobile ? '-15px 0px -30px 0px' : '-15px 0',

          transform: isMobile ? 'scale(0.7)' : 'scale(0.8)',

        }}
      >


        <h1
          style={{
            fontFamily: 'Poppins',
            color: 'white',
            fontSize: '71px',
            position: 'relative',
            fontWeight: 800,
            letterSpacing: '10px',
            fontStyle: 'italic',
            lineHeight: '58px',
            marginTop: '20px'
          }}>
          wrdz
          <div>
            .app

          </div>

        </h1>

      </div>


      <div
        className={'animate__animated animate__delay-1s animate__fadeIn'}

        style={{
          color: 'white',
          marginBottom: '20px',
          fontSize: isMobile ? '14px' : '16px',
          maxWidth: '440px',
          textAlign: "center",
          // margin: '30px 0',
          margin: isMobile ? '20px 0' : '30px 0',

        }}
      >
        This app is a pronunciation and spelling trainer
        that you can download for free for your mobile.
      </div>

      <div
        className={'animate__animated animate__delay-1s animate__fadeIn'}

        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transform: isMobile && 'scale(0.7)',

        }}
      >
        <div
          style={{
            width: '179px',
            marginRight: '10px'
          }}
        >
          <img
            src={'/play.png'}
            style={{height: '50px'}}
            alt={'Google photo'}
          />
        </div>

        <div
          style={{
            width: '161px'
          }}
        >
          <img
            src={'/ios.png'}
            style={{height: '50px'}}
            alt={'IOS photo'}


          />
        </div>
      </div>
      <div
        className={'animate__animated animate__delay-1s animate__fadeIn'}

        style={{
          color: 'white',
          // marginBottom: '20px',
          marginBottom: isMobile ? '0px' : '20px',

          fontSize: isMobile ? '14px' : '18px',

          marginTop: isMobile ? '20px' : '30px',

          fontStyle: 'italic',

        }}
      >
        Made by <a style={{
        color: 'white',

      }} href={'https://thetime.pl'}>thetime.pl</a>
      </div>
    </Wrapper>
  );
}

export default Home;
