import React, {useState} from 'react';
import {Button, Card, Form, Input, message} from "antd";
import axios from "axios";


const Login = ({setToken, token}) => {

  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)

    try {
      const {data} = await axios(`${process.env.REACT_APP_ENDPOINT_URL}/token`, {
        method: 'POST',
        data: values
      })

      const token = data?.access

      localStorage.setItem('token', token)

      setToken(token)
    } catch (e) {
      message.error('Server error')
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card
      style={{
        marginTop: '50px',
        maxWidth: '400px',
        margin: '50px auto 0 auto'
      }}
    >
      <Form
        initialValues={{
          username: 'john'
        }}
        name="basic"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{required: true, message: 'Please input your username!'}]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{required: true, message: 'Please input your password!'}]}
        >
          <Input.Password/>
        </Form.Item>


        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>

    </Card>

  );
};

export default Login;
