import React, {useEffect, useState} from 'react';
import axios from "axios";
import {frontUrl} from "./Admin";
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Spin, Table, Tag} from "antd";
import _ from 'lodash'

const renderDiff = (a, b, pre = 1) => {
  const res = a - b
  return `${(res >= 0) ? '+' : ''}${_.round(res, pre)}`
}

const columns = [
  {
    title: 'English',
    dataIndex: 'english',
    key: 'english',
  }, {
    title: 'Level',
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: 'Polish',
    dataIndex: 'polish',
    key: 'polish',
  },
  // {
  //   title: 'POS',
  //   dataIndex: 'pos',
  //   key: 'pos',
  // },
  {
    title: 'Warnings',
    dataIndex: 'warning',
    key: 'warning',
  }, {
    title: 'Sound count',
    dataIndex: 'soundCount',
    key: 'soundCount',
  }, {
    title: 'Star count',
    dataIndex: 'starCount',
    key: 'starCount',
  }, {
    title: 'Share count',
    dataIndex: 'shareCount',
    key: 'shareCount',
  }, {
    title: 'Link',
    dataIndex: 'shareCount',
    key: 'shareCount',
    render: (_, data) => {
      const url = `${frontUrl}/${data.slug}`
      return <div
        style={{cursor: 'pointer'}}
        onClick={() => {
          message.success('Copy to clipboard')
          navigator.clipboard.writeText(url)}}
      >{url}</div>
    }
  },{
    title: 'Tools',
    dataIndex: 'shareCount',
    key: 'shareCount',
    render: (_, data) => {
      const url = `${frontUrl}/${data.slug}`
      return <Button onClick={() => data.onEdit(data)} type={'primary'}>Edit</Button>
    }
  },
  // {
  //   title: 'AM',
  //   dataIndex: 'am',
  //   key: 'am',
  // },
];

const WordModal = ({isOpen, onClose, word, token, onSaveCallback}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState()
  const [fetching, setFetching] = useState()
  const [stats, setStats] = useState({})

  const {
    count,
    starCount,
    shareCount,
    timesCount,
    all
  } = stats

  const aveTimesInSec = timesCount / count / 1000
  const aveStars = _.round(starCount / count, 1)
  const aveShares = _.round(shareCount / count, 1)

  const save = async (values) => {
    try {
      setLoading(true)
      const headers = {
        'Authorization': `Bearer ${token}`,
      }

      await axios(word.url, {
        method: 'PUT',
        headers,
        data: {...word, ...values}
      })
      onSaveCallback()
      message.success('Success!!!')

    } catch (e) {
      message.error('Error!!!')

    } finally {
      setLoading(false)
    }

  }

  const fetchData = async (uid) => {
    try {
      setFetching(true)
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/getWordStats/${uid}`;
      const {data} = await axios(url)
      setStats(data)
    } catch (e) {

    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue(word)
    fetchData(word.uid)
  }, [])

  const averageTimeParse = JSON.parse(word.times)
  let averageTime = _.sum(averageTimeParse) / averageTimeParse.length / 1000

  if (averageTimeParse.length === 0) {
    averageTime = 0
  }

  const url = `${frontUrl}/${word.slug}`

  return <Modal
    width={'800px'}
    onCancel={onClose}
    visible={isOpen}
    title={'Edit'}
    onOk={() => form.submit()}
    okButtonProps={{
      loading
    }}
  >
    {fetching && <Spin/>}
    {!fetching && <>
      <div
        style={{cursor: 'pointer'}}
        onClick={() => {
          message.success('Copy to clipboard')
          navigator.clipboard.writeText(url)}}
      >{url}</div>
      <div
        style={{display: 'flex'}}
      >

        <div
          style={{padding: '20px 20px 20px 0', fontSize: '18px'}}
        >


          <div
            style={{marginBottom: '6px'}}
          >
            <Tag
              style={{fontSize: '18px', padding: '6px'}}

              color="purple">All words in level: {count} / {all}</Tag>
            <Tag
              style={{fontSize: '18px', padding: '6px'}}
              color="magenta">Warnings: {word.warning}</Tag>
            <Tag
              style={{fontSize: '18px', padding: '6px'}}
              color="green">MP3 Replay: {word.soundCount}</Tag>
          </div>

          <Tag
            style={{fontSize: '18px', padding: '6px'}}
            color="cyan">Starred: {word.starCount} / {aveStars} {renderDiff(word.starCount, aveStars)}</Tag>
          <Tag
            style={{fontSize: '18px', padding: '6px'}}
            color="blue">Shares: {word.shareCount} / {aveShares} {renderDiff(word.shareCount, aveShares)}</Tag>
          <Tag
            style={{fontSize: '18px', padding: '6px'}}
            color="geekblue">Average time: {_.round(averageTime, 1)}s
            / {_.round(aveTimesInSec, 1)}s {renderDiff(averageTime, aveTimesInSec)}</Tag>

        </div>

      </div>


      <Form
        onFinish={save}
        layout={'vertical'}
        form={form}
        name="basic"
        labelCol={{span: 8}}
        // wrapperCol={{span: 24}}
        initialValues={{word}}

        autoComplete="off"
      >
        <Row gutter={[20, 20]}>
          <Col sm={8}>
            <Form.Item
              // wrapperCol={{
              //   sm: 12
              // }}
              label="English"
              name="english"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item
              label="Polish"
              name="polish"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item
              label="Level"
              name="level"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <InputNumber
                min={1}
                max={10}
              />
            </Form.Item>
          </Col>

        </Row>


        <Row
          gutter={[20, 20]}
        >
          <Col sm={8}>
            <Form.Item
              label="POS"
              name="pos"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>

          </Col><Col sm={8}>
          <Form.Item
            label="AM"
            name="am"
            rules={[{required: true, message: 'Please fill this field!'}]}
          >
            <Input/>
          </Form.Item>

        </Col><Col sm={8}>
          <Form.Item
            label="BR"
            name="br"
            rules={[{required: true, message: 'Please fill this field!'}]}
          >
            <Input/>
          </Form.Item>

        </Col>
        </Row>


        <Row
          gutter={[20, 20]}
        >
          <Col sm={8}>
            <Form.Item
              label="Spanish"
              name="spanish"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>

          </Col><Col sm={8}>
          <Form.Item
            label="German"
            name="german"
            rules={[{required: true, message: 'Please fill this field!'}]}
          >
            <Input/>
          </Form.Item>

        </Col><Col sm={8}>
          <Form.Item
            label="French"
            name="french"
            rules={[{required: true, message: 'Please fill this field!'}]}
          >
            <Input/>
          </Form.Item>

        </Col>
        </Row>


        <Row
          gutter={[20, 20]}
        >
          <Col sm={8}>
            <Form.Item
              label="Italian"
              name="italian"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>

          </Col><Col sm={8}>
          <Form.Item
            label="Chinese"
            name="chinese"
            rules={[{required: true, message: 'Please fill this field!'}]}
          >
            <Input/>
          </Form.Item>

        </Col>
          <Col sm={8}>
            <Form.Item
              label="Japanese"
              name="japanese"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>


        <Row
          gutter={[20, 20]}
        >
          <Col sm={8}>
            <Form.Item
              label="Russian"
              name="russian"
              rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item
              // wrapperCol={{
              //   sm: 12
              // }}
              label="Warnings"
              name="warning"
              // rules={[{required: true, message: 'Please fill this field!'}]}
            >
              <InputNumber/>
            </Form.Item>
          </Col>


        </Row>
      </Form>
    </>}


  </Modal>
}
const WordsTable = ({token}) => {

  const [loading, setLoading] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedWord, setSelectedWord] = useState()
  const [currPage, setCurrPage] = useState(1)
  const [sortBy, setSortBy] = useState()
  const [search, setSearch] = useState('')

  const [currentData, setCurrentData] = useState({})

  const fetchWords = async (page) => {

    try {
      setLoading(true)
      const headers = {
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*"
      }

      const {data} = await axios(`${process.env.REACT_APP_ENDPOINT_URL}/api/words`, {
        method: 'GET',
        headers,
        params: {page, sortBy, search}
      })

      setCurrentData(data)

    } catch (e) {

    } finally {
      setLoading(false)
    }

  }

  const onClick = (word) => {
    setModalOpen(true)
    setSelectedWord(word)
  }

  useEffect(() => {
    fetchWords(currPage)
  }, [sortBy, search])

  const paginationConfig = {
    position: ['bottomLeft'],
    defaultPageSize: 30,
    showSizeChanger: false,
    total: currentData?.count,
    onChange: (page) => {
      setCurrPage(page)
      fetchWords(page)
    }
  }

  return (
    <div>
      {modalOpen && <WordModal
        token={token}
        word={selectedWord}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSaveCallback={() => {
          fetchWords(currPage)
        }}
      />}

      <Space
        style={{marginBottom: '10px'}}
      >
        <Input
          placeholder={'Search...'}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />

        <Select
          placeholder={'Sort by'}
          style={{width: '170px'}}
          value={sortBy}
          onChange={e => setSortBy(e)}
          options={[
            {
              value: 'warning',
              label: 'Warnings'
            }, {
              value: 'soundCount',
              label: 'Sound count'
            }, {
              value: 'starCount',
              label: 'Star count'
            }, {
              value: 'shareCount',
              label: 'Share count'
            },
          ]}
        />
      </Space>
      <Table
        loading={loading}
        columns={columns}
        dataSource={currentData.results?.map(r => ({...r, onEdit: onClick})) || []}
        rowKey={record => record.uid}
        pagination={paginationConfig}
        // onRow={(record) => {
        //   return {
        //     onClick: () => onClick(record),
        //   };
        // }}
      />
    </div>
  );
};

export default WordsTable;
