import React from 'react';
import './App.css';
import 'animate.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import Home from './components/Home';
import {BrowserRouter, Route, Switch,Redirect} from "react-router-dom";
import Admin from "./components/Admin";
// import SingleWord from "./components/SingleWord";
import Cztery from "./components/Cztery";

function App() {

  return <div
    style={{fontFamily: 'Poppins'}}
  >
    <BrowserRouter>
      <Switch>
        <Route exact path={'/'} component={Admin}/>
        {/*<Route exact path={'/'} component={Home}/>*/}
        {/*<Route exact path={'/admin'} component={Admin}/>*/}
        <Route exact path={'/404'} component={Cztery}/>
        {/*<Route exact path={'/:slug'} component={SingleWord}/>*/}
        <Redirect to={'/404'}/>
      </Switch>
    </BrowserRouter>
  </div>
}

export default App;
