import React, {useState} from 'react';
import {Button, Layout, Tabs} from 'antd';
import Login from "./Login";
import WordsTable from "./WordsTable";
import WordsUplaod from "./WordsUplaod";
import AppSettings from "./AppSettings";

const {Header} = Layout;
const {TabPane} = Tabs;

export const frontUrl = `https://wrdz.app`

const Admin = () => {

  const [token, setToken] = useState(localStorage.getItem('token'))

  if (!token) return <Login setToken={setToken} token={token}/>

  return (
    <>
      <div
        style={{
          height: '100vh',
        }}
      >
        <Header>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%'
          }}>
            <div
              style={{color: 'white'}}
            >
              WRDZ APP - Admin
            </div>

            <Button
              onClick={() => {
                localStorage.removeItem('token')
                setToken(null)
              }}
            >
              Log out
            </Button>
          </div>


        </Header>
        <div style={{padding: '20px 50px', minHeight: 'calc(100% - 64px)', background: 'white'}}>

          <Tabs
            defaultActiveKey="1"
            destroyInactiveTabPane={true}
          >
            <TabPane
              tab="All Words"
              key="1"
            >
              <WordsTable
                token={token}
              />
            </TabPane>
            <TabPane
              tab="Upload words"
              key="2"
            >
              <WordsUplaod
                token={token}
              />
            </TabPane>
            <TabPane
              tab="Mobile App Settings"
              key="3"
            >
              <AppSettings
                token={token}
              />
            </TabPane>
          </Tabs>

        </div>
      </div>
    </>
  );
};

export default Admin;
