import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, InputNumber, message, Row, Spin, Typography} from "antd";
import axios from "axios";


const AppSettings = ({token}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState()
  const [saving, setSaving] = useState()


  const save = async (values) => {
    console.log(JSON.stringify(values), 'cvwa')
    try {
      setSaving(true)
      const headers = {
        'Authorization': `Bearer ${token}`,
      }

      await axios(`${process.env.REACT_APP_ENDPOINT_URL}/updateSettings`, {
        method: 'POST',
        headers,
        data: {config: JSON.stringify(values)}
      })

      message.success('Success!!!')

    } catch (e) {
      message.error('Error!!!')

    } finally {
      setSaving(false)
    }

  }

  const loadSettings = async () => {
    try {
      setLoading(true)
      const headers = {
        'Authorization': `Bearer ${token}`,
      }

      const {data} = await axios(`${process.env.REACT_APP_ENDPOINT_URL}/getSettings`, {
        method: 'GET',
        headers,
      })

      const parsedData = JSON.parse(data);
      form.setFieldsValue(parsedData)
      console.log(data, 'data')

    } catch (e) {
      message.error('Error!!!')

    } finally {
      setLoading(false)
    }

  }

  useEffect(async () => {
    await loadSettings()
  }, [])

  if (loading) return <Spin size={'large'}/>

  return (
    <div>
      <Typography.Title>
        Level descriptions:
      </Typography.Title>
      <Divider/>
      <Form

        onFinish={save}
        layout={'vertical'}
        form={form}
        name="basic"
        labelCol={{span: 8}}
        autoComplete="off"
      >
        <Row gutter={[20, 0]}>
          {["1", '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((e, index) =>
            <Col sm={8} key={index}>
              <Form.Item
                // wrapperCol={{
                //   sm: 12
                // }}
                label={`Level: ${e}`}
                name={['levelDescriptions', e]}
              >
                <Input/>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Typography.Title>
            Other:
          </Typography.Title>
          <Divider/>
          <Col sm={12}>
            <Form.Item
              // wrapperCol={{
              //   sm: 12
              // }}
              label={`Recording duration ms (1000ms = 1s)`}
              name={['recordingDuration']}
            >
              <InputNumber min={0} max={99999999}/>
            </Form.Item>
          </Col>

          <Col sm={12}>
            <Form.Item
              // wrapperCol={{
              //   sm: 12
              // }}
              label={`Starred word chance (%)`}
              name={['starChance']}
            >
              <InputNumber
                min={0}
                max={100}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Typography.Title>
            YouTube Link:
          </Typography.Title>
          <Divider/>
          <Col sm={12}>
            <Form.Item
                label={`YouTube Link`}
                name={['introduction_video_yt_link']}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button
        loading={saving}
        type={'primary'}
        onClick={() => form.submit()}
      >
        Save
      </Button>
    </div>
  );
};

export default AppSettings;
